import QRCode from "qrcode";

export default {
  methods: {
    generateQrCode(qrFilePath, callback) {
      QRCode.toDataURL(qrFilePath)
        .then((url) => {
          callback(url);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
